import * as React from 'react';
// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './InfoModule.module.scss';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

import Lightbulb from '../../public/img/icon-lightbulb.svg';
import Conversation from '../../public/img/icon-conversation.svg';
import Presentation from '../../public/img/icon-presentation.svg';

const indexToContainerName = {
  0: 'First',
  1: 'Second',
  2: 'Third',
};

const indexToIconName = {
  0: 'Lightbulb',
  1: 'Conversation',
  2: 'Presentation',
};

const InfoModule = ({ items = [] }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Body}>
        {items.map((item, idx) => {
          const containerClasses = classNames(
            styles.Container,
            styles[`Container--${indexToContainerName[idx]}`]
          );
          const iconClasses = classNames(
            styles.Icon,
            styles[`Icon--${indexToIconName[idx]}`]
          );

          const modules = (
            <React.Fragment key={idx}>
              <div className={containerClasses}>
                <div className={iconClasses}>
                  {item.icon === 'lightbulb' && (
                    <Lightbulb className={styles.Lightbulb} />
                  )}
                  {item.icon === 'conversation' && (
                    <Conversation className={styles.Conversation} />
                  )}
                  {item.icon === 'presentation' && (
                    <Presentation className={styles.Presentation} />
                  )}
                </div>
                <div className={styles.Cards}>
                  <h3 className={styles.Header}>{item.title}</h3>
                  <p className={styles.Text}>{item.text}</p>
                </div>
              </div>
            </React.Fragment>
          );
          return modules;
        })}
      </div>
    </div>
  );
};

InfoModule.propTypes = {};

InfoModule.defaultProps = {};

// export default basePageWrap(InfoModule);
export default InfoModule;
