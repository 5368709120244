// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './StatisticModule.module.scss';
import Image from 'next/legacy/image';
import Circle from '../CircleModule/CircleModule.js';

const StatisticModule = ({ items = [], image, title }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Body}>
        <h2 className={styles.Header}>
          <span className={styles.HeaderText}>
            {title}
            <div className={`${styles.Circle}`}>
              <Circle />
            </div>
          </span>
        </h2>
        <div className={styles.Info}>
          <div className={styles.Container}>
            {items.map((item, title) => {
              return (
                <div key={title} className={styles.Flex}>
                  <div>
                    <span aria-hidden={true} className={styles.Number}>
                      {item.quantity}
                    </span>
                  </div>
                  <div className={styles.TextContainer}>
                    <h3 className={styles.Title}>
                      <span className="sr-only">{item.quantity} </span>
                      {item.title}
                    </h3>
                    <p className={styles.Text}>{item.text}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {image && (
            <div className={styles.ImageContainer}>
              <Image
                src={image}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                // priority={true}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StatisticModule.propTypes = {};

StatisticModule.defaultProps = {};

// export default basePageWrap(StatisticModule);
export default StatisticModule;
