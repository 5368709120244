// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './CreateAccountBlob.module.scss';

const Blob = () => {
  return (
    <div className={styles.Root}>
      <span className={styles.ButtonText}>Skapa konto</span>
      <div id="Shape" className={styles.Shape}></div>
    </div>
  );
};

Blob.propTypes = {};

Blob.defaultProps = {};

// export default basePageWrap(Blob);
export default Blob;
