import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import Link from 'next/link';
import CreateAccountBlob from '../../components/CreateAccountBlob/CreateAccountBlob';
import HeroCurve from '../../public/img/wavy.svg';
import * as mtm from '../../utils/mtm';
import styles from './Hero.module.scss';

const Hero = ({ vimeoLink, image, showCreateAccount = false }) => {
  const { t } = useTranslation();
  const Vimeo = dynamic(() => import('@u-wave/react-vimeo'), { ssr: false });

  return (
    <div className={styles.Root}>
      <div className={styles.Body}>
        {vimeoLink ? (
          <div className={styles.BackgroundVideo}>
            <Vimeo
              video={vimeoLink}
              autoplay={true}
              controls={false}
              background
              loop={true}
              responsive={true}
            />
            <HeroCurve className={styles.HeroCurveVideo} />
          </div>
        ) : (
          <div className={styles.BackgroundImg}>
            {image && (
              <Image src={image} layout="fill" alt="" objectFit="cover" />
            )}
          </div>
        )}
        {!vimeoLink && <HeroCurve className={styles.HeroCurve} />}

        {showCreateAccount && (
          <Link
            href={'/wt/saml2/login/'}
            onClick={() => {
              mtm.ctaButtonClick(t('Generic.createAccount'), 'hero-blob');
            }}
          >
            <span className="sr-only">{t('Generic.createAccount')}</span>
            <div className={styles.BlobWrapper}>
              <CreateAccountBlob />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

Hero.propTypes = {};

Hero.defaultProps = {};

// export default basePageWrap(Hero);
export default Hero;
