import * as React from 'react';
import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './OnboardingModule.module.scss';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import SquigglyLine from '../../public/img/squiggly-line.svg';
import SquigglyLineDown from '../../public/img/squiggly-line-down.svg';
import Circle from '../../public/img/icon-circle.svg';
import StarIcon from '../../public/img/icon-star.svg';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import Bulb1 from '../../public/img/vector-bulb-1.svg';
import Bulb2 from '../../public/img/vector-bulb-2.svg';
import Bulb3 from '../../public/img/vector-bulb-3.svg';

const ComponentMapper = [Bulb1, Bulb2, Bulb3];

const OnboardingModule = ({ title, items = [], isAuthenticated, user }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const { t } = useTranslation();

  const ButtonContainerStyles = classNames(styles.ButtonContainer, {
    [styles.AddMargin]: !isAuthenticated,
    [styles.HideWhenLoggedIn]: isAuthenticated,
  });

  return (
    <div className={styles.Root}>
      <div className={styles.Body}>
        <div className={styles.HeaderWrapper}>
          <h2 className={styles.Header}>{title}</h2>
          <div className={`${inView ? styles.Circle : styles.Hide}`}>
            <Circle />
          </div>
        </div>
        <div className={styles.AnimationContainer} ref={ref}>
          <div className={`${inView && styles.Star}`}>
            <StarIcon />
          </div>
          <div className={styles.LineContainer}>
            <div className={`${inView && styles.Line}`}>
              <SquigglyLine />
            </div>
            <div className={`${inView && styles.LineDown}`}>
              <SquigglyLineDown />
            </div>
          </div>

          <div className={styles.Container}>
            {items.map((item, index) => {
              const Icon = ComponentMapper[index];
              const iconClass = classNames(
                styles.Icon,
                styles[`Icon--${index + 1}`]
              );

              return (
                <div key={item.title} className={styles.Card}>
                  <div className={styles.BulbContainer}>
                    <div className={styles.Bulb}>
                      <Icon className={iconClass} aria-hidden={true} />
                    </div>
                    <span className={styles.Number}>{index + 1}</span>
                  </div>
                  <h3 className={styles.Title}>{item.title}</h3>
                  <p className={styles.Text}>{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={ButtonContainerStyles}>
          {user.role === 'advisor' && (
            <ButtonPrimary
              isLink={true}
              href={'/installningar/lagg-till-aktivitet/'}
              mtmData={{
                event: 'ctaButtonClick',
                ctaText: t('OnboardingModule.createActivity'),
                ctaFormat: 'primary-button',
              }}
            >
              {t('OnboardingModule.createActivity')}
            </ButtonPrimary>
          )}

          {user.role !== 'advisor' && isAuthenticated && (
            <ButtonPrimary
              isLink={true}
              href={'/boka-tid/'}
              mtmData={{
                event: 'ctaButtonClick',
                ctaText: t('OnboardingModule.bookCounseling'),
                ctaFormat: 'primary-button',
              }}
            >{`${t('OnboardingModule.bookCounseling')}`}</ButtonPrimary>
          )}

          {user.role !== 'advisor' && !isAuthenticated && (
            <ButtonPrimary
              isLink={true}
              href={'/wt/saml2/login/'}
              mtmData={{
                event: 'ctaButtonClick',
                ctaText: t('OnboardingModule.createActivity'),
                ctaFormat: 'primary-button',
              }}
            >
              {`${t('OnboardingModule.createAccount')}`}
            </ButtonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

OnboardingModule.propTypes = {};

OnboardingModule.defaultProps = {};

// export default basePageWrap(OnboardingModule);
export default OnboardingModule;
