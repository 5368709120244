import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './StartPageUser.module.scss';
import OnboardingModule from '../../components/OnboardingModule';
import StatisticModule from '../../components/StatisticModule';
import InfoModule from '../../components/InfoModule';
import Hero from '../../components/Hero';
import Image from 'next/legacy/image';
import Card from '../../components/Card';
import ButtonPrimary from '../../components/ButtonPrimary';
import classNames from 'classnames';
import * as mtm from '../../utils/mtm';

const StartPageUser = ({
  hero,
  isAuthenticated,
  blocks = [],
  bookings = [],
  user = {},
}) => {
  const { t } = useTranslation();

  if (
    typeof window !== 'undefined' &&
    window.location.search.includes('loggedin')
  ) {
    mtm.login(user?.role);
    // remove ?loggedin so we dont track it again if user uses back button
    window.history.replaceState('', '', '/');
  }

  const TopVectorClasses = classNames(styles.TopVectorContainer, {
    [styles.TopVectorLoggedOut]: !isAuthenticated,
    [styles.TopVectorLoggedIn]: isAuthenticated,
  });

  return (
    <div className={styles.Root}>
      {!!hero && <Hero showCreateAccount={!isAuthenticated} {...hero} />}
      <div className={styles.ElementContainer}>
        <div className={styles.Body}>
          <div className={styles.Container}>
            {/* {bookings.length > 0 &&
              bookings.map((booking) => {
                return <EventListCard isBooked={true} {...booking} />;
              })} */}
          </div>
          <div className={TopVectorClasses}>
            <div className={styles.TopVector}>
              <Image alt="vector-image" src="/img/vector.svg" layout="fill" />
            </div>
          </div>

          <div>
            {bookings?.length > 0 && (
              <Info
                isAuthenticated={isAuthenticated}
                user={user}
                bookings={bookings}
              />
            )}
          </div>
          <div className={styles.ModuleWrapper}>
            {blocks.map((item, i) => {
              return (
                <div key={i}>
                  {item.type === 'info' && isAuthenticated && (
                    <div className={styles.InfoModuleWrapper}>
                      <InfoModule {...item.value} />
                    </div>
                  )}
                  {item.type === 'onboarding' && (
                    <OnboardingModule
                      {...item.value}
                      key={'onboarding' + i}
                      isAuthenticated={isAuthenticated}
                      user={user}
                    />
                  )}
                  {item.type === 'statistic' && (
                    <StatisticModule
                      {...item.value}
                      key={'statistic' + i}
                      isAuthenticated={isAuthenticated}
                      user={user}
                    />
                  )}
                  {i == 1 && (
                    <div className={styles.MidVectorContainer}>
                      <div className={styles.MidVector}>
                        <Image alt="" src="/img/vector3.svg" layout="fill" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Info = ({ isAuthenticated, user, bookings = [] }) => {
  const { t } = useTranslation();

  return (
    <div>
      {isAuthenticated && (
        <div className="container">
          <h1 className={styles.UserTitle}>
            {t('StartPageUser.hello')} {user.name}!
          </h1>
          <h2 className={styles.Subheader}>
            {t('StartPageUser.upcomingActivities')}
          </h2>

          {bookings.map((booking) => {
            if (user.isAdvisor) {
              return <Card cardType="advisor" key={booking.id} {...booking} />;
            } else {
              return <Card cardType="user" key={booking.id} {...booking} />;
            }
          })}
          <div className={styles.ButtonWrapper}>
            <ButtonPrimary isLink={true} href="/mina-aktiviteter/">
              {t('StartPageUser.seeMyActivities')}
            </ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
};

StartPageUser.propTypes = {};

export default basePageWrap(StartPageUser);
