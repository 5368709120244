// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './CircleModule.module.scss';
import Circle from '../../public/img/icon-circle.svg';
import { useInView } from 'react-intersection-observer';

const CircleModule = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <div className={styles.Root}>
      <div className={`${inView && styles.Circle}`}>
        <Circle />
      </div>
    </div>
  );
};

CircleModule.propTypes = {};

CircleModule.defaultProps = {};

// export default basePageWrap(CircleModule);
export default CircleModule;
